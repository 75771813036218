export function callInstagram(){
       window.open("https://www.instagram.com/nexera.cet?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==", "_blank");
}
export function callLinkedIn(){
        window.open("https://www.linkedin.com/", "_blank");
}
export function Order(){
        window.open("https://www.ieacet.in");
}

